import React, { useState, useEffect } from 'react';
import useModal from './useModal';
import Menu from "./menu";
import { Link } from "gatsby"

const Header = (props) => {

	const { isShowing, toggle } = useModal();
	const [deviceType, setDeviceType] = useState("Desktop");
	const [activeIndex, setActiveIndex] = useState(0);

	let plusClass = "orange";
	if (props.type == "single") {
		plusClass = "white";
	}
	if (isShowing) { plusClass = "blue"; }

	let others = props.type;
	let left = props.left || "designer";

	const handleOnClick = index => {
		setActiveIndex(index);
	};

	const handleUserKeyPress = event => {
		const { keyCode } = event;
		if (keyCode == 27) {
			toggle();
		}
	};

	const links = [
		{
			name: "intro",
			to: "#intro",
			className: "menu-item"
		},
		{
			name: "portfólio",
			to: "#portfolio",
			className: "menu-item"
		},
		{
			name: "clientes",
			to: "#clients",
			className: "menu-item"
		},
		{
			name: "contactos",
			to: "#contacts",
			className: "menu-item"
		}
	];


	const navLinks = links.map((link, index) => {
		return (
			<a key={index} onClick={() => handleOnClick(index)} className={activeIndex === index ? "menu-item active" : "menu-item"} href={link.to} data-scroll-to>{link.name}</a>
		);
	});

	// pass prop as class
	// const white = props.white ? "white" : "";
	const white = "";


	useEffect(() => {

		if (window.innerWidth <= 1023) {
			setDeviceType("Mobile");
		} else {
			setDeviceType("Desktop");
		}

		const resizeW = function () {


			if (window.innerWidth <= 1023) {
				if (deviceType !== "Mobile") {
					location.reload();
				}
			}

			if (window.innerWidth > 1023) {
				if (deviceType !== "Desktop") {
					location.reload();
				}
			}

		}

		window.addEventListener("resize", resizeW); // Update the width on resize
		window.addEventListener("keydown", handleUserKeyPress);

		return () => {
			window.removeEventListener("keydown", handleUserKeyPress);
			window.removeEventListener("resize", resizeW);
		};

	});


	return (
		<header className={isShowing ? 'header max-width p-relative z-index' : 'header max-width p-relative'}>
			<div className="row">
				<div id="logoWrapper" className="col-lg-7 col-xs-12 logo-wrapper" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction">
					<Link to="/" id="headerLogo" className="logo"></Link>
				</div>
				{others ?
					<nav className="menu col-xs-offset-7">
						<div className="menu-wrapper" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction">
							<button className={`open-menu open-menu--${plusClass}`} onClick={toggle}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73.4" xmlSpace="preserve">
									<path d="M30 30.4V0h13v30.4h30V43H43v30.4H30V43H0V30.4h30z" />
								</svg>
							</button>
						</div>
						<Menu isShowing={isShowing} left={left} showreelModal={props.showreelModal} />
					</nav>
					:
					<nav id="menu" className="menu">
						<div className="menu-wrapper hidden-xs show-md" data-scroll data-scroll-sticky data-scroll-target="#scroll-direction">
							{navLinks}
							<span onClick={() => props.showreelModal(true)} id="modalMenu" className="menu-item-orange">showreel</span>
						</div>
					</nav>}
				<div className="left-logo-wrapper">
					<Link to="/" className="logo--md hidden-md" />
				</div>
				<div id="leftSticky" className="left-designer-wrapper">
					<div data-scroll data-scroll-sticky data-scroll-target="#leftSticky">
						<div id="leftDesigner" className="left-designer">
							{left}
						</div>
					</div>
				</div>
				<div id="fixedEmail" className="email-wrapper">
					<div data-scroll data-scroll-sticky data-scroll-target="#fixedEmail" data-scroll-offset="-90%">
						<div className="fixed-email">
							<Link to="/contactos">email</Link>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header

