import React, { useEffect } from 'react'
import Header from './header'
import Footer from './footer'
import PropTypes from 'prop-types'
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import Seo from './seo'

const Template = (props) => {

	const modal = props.visible;
	const { children } = props
	const { scroll } = useLocomotiveScroll()

	let pageClass = "";
	if (props.type == "others") {
		pageClass = "others logotipos";
	} else if (props.type == "design-grafico") {
		pageClass = "others design-grafico";
	} else if (props.type == "branding") {
		pageClass = "others branding";
	} else if (props.type == "naming") {
		pageClass = "others naming";
	} else if (props.type == "post") {
		pageClass = "others post";
	} else if (props.type == "single") {
		pageClass = "others single";
	}



	useEffect(() => {

		if (scroll && props.type != "post") {
			if (window.innerWidth > 768) {
				let fox = document.documentElement.querySelector(".fox-icon-circle");
				let logoWrapper = document.documentElement.querySelector("#logoWrapper");
				let headerLogo = document.documentElement.querySelector("#headerLogo");
				let leftDesigner = document.documentElement.querySelector("#leftDesigner");

				scroll.on("scroll", args => {

					let offset = args.scroll.y / 2.4,
						offsetLeft = args.scroll.y / 8.4,
						dimension = 212,
						leftVal = -29,
						topVal = 100,
						height = dimension - offset,
						left = leftVal - offsetLeft,
						top = topVal - offsetLeft;


					// set fox rotate
					if (typeof args.currentElements['rotateFoxIcon'] === 'object') {
						fox.style.transform = "rotate(" + args.scroll.y + "deg)";
					}

					// set logo height and left design opacity
					if (height > 20) {
						headerLogo.style.height = height + "px";
						leftDesigner.style.opacity = 0.2 + args.scroll.y / 700;
					} else {
						headerLogo.style.height = 20 + "px";
						leftDesigner.style.opacity = 1;
					}


					// set logoWrapper position
					if (left < -29) {
						logoWrapper.style.left = left + "px";
					} else {
						logoWrapper.style.left = -29 + "px";
					}

					if (left < -64) {
						logoWrapper.style.left = -64 + "px";
					}

					if (top < 100) {
						logoWrapper.style.top = top + "px";
					} else {
						logoWrapper.style.top = 100 + "px";
					}

					if (top < 50) {
						logoWrapper.style.top = 50 + "px";
					}

				});
			}

		}
	}, [scroll])


	return (
		<div id="scroll-direction" className={`container-fluid p-relative ${pageClass}`}>
			<Seo/>
			<Header white={modal} type={props.type} left={props.left} showreelModal={props.showreelModal} />
			{children}
			<Footer background={props.background} type={props.type} />
		</div>
	)

}

Template.propTypes = {
	callbacks: PropTypes.string,
	visible: PropTypes.bool,
	children: PropTypes.node.isRequired,
	showreelModal: PropTypes.func,
	detail: PropTypes.string
};

export default Template
